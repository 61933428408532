import "./App.css";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Category from "./pages/Category";
import AddCategory from "./pages/AddCategory";
import Products from "./pages/Products";
import AddProducts from "./pages/AddProducts";
import ProductsDetails from "./pages/ProductsDetails";
import Order from "./pages/Order";
import CreateOrder from "./pages/CreateOrder";
import EditProduct from "./pages/EditProduct";
import EditCategory from "./pages/EditCategory";
import ChangePassword from "./pages/ChangePassword";
import AddOrderItems from "./pages/AddOrderItems";
import EditOrder from "./pages/EditOrder";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { itemContext } from "./pages/itemContext";
import FilterOrder from "./pages/FilterOrder";
import OrderHistory from "./pages/OrderHistory";
import FilterByProcust from "./pages/FilterByProduct";
import InWash from "./pages/InWash";
import AddWhatsappNote from "./pages/AddWhatsappNote";
import SendWhatsappMsg from "./pages/SendWhatsappMsg";
import WhatsappList from "./pages/WhatsappList";
import EditWhatsapp from "./pages/EditWhatsapp";

function App() {
  const { toggleMenu, setToggleMenu } = useContext(itemContext);
  return (
    <>
      <div className={`main-wrapper ${toggleMenu ? "slide-nav" : ""}`}>
        <ToastContainer />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/category" element={<Category />} />
          <Route path="/add-category" element={<AddCategory />} />
          <Route path="/add-whatsapp" element={<AddWhatsappNote />} />
          <Route path="/whatsapp-list" element={<WhatsappList />} />
          <Route path="/send-whatsapp/:id" element={<SendWhatsappMsg />} />
          <Route path="/edit-category/:id" element={<EditCategory />} />
          <Route path="/edit-whatsapp/:id" element={<EditWhatsapp />} />
          <Route path="/products" element={<Products />} />
          <Route path="/add-product" element={<AddProducts />} />
          <Route path="/edit-product/:id" element={<EditProduct />} />
          {/* <Route path="/products-details" element={<ProductsDetails />} /> */}
          <Route path="/order" element={<Order />} />
          <Route path="/create-order" element={<CreateOrder />} />
          <Route path="/order-history" element={<OrderHistory />} />
          <Route path="/filter-by-product/:id" element={<FilterByProcust />} />
          <Route path="/filter-order" element={<FilterOrder />} />
          <Route path="/edit-order/:id" element={<EditOrder />} />
          <Route path="/add-order-items/:id" element={<AddOrderItems />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/inwash" element={<InWash />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
