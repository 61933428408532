import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { itemContext } from "./itemContext";
import { FiSearch } from "react-icons/fi";

const FilterOrder = () => {
  const { formatDate, toastError, toastSuccess } = useContext(itemContext);
  const navigate = useNavigate();
  const [issueOrders, setIssueOrders] = useState(null);
  const [returnOrders, setReturnOrders] = useState(null);
  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [itemModel, setItemModel] = useState(false);
  const [modelItems, setModelItems] = useState(null);
  const [date, setDate] = useState("");
  const [search, setSearch] = useState("");

  const getFilterOrders = () => {
    try {
      const token = localStorage.getItem("t5kvs24s46a3n");
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}order/filter?date=${date}&search=${search}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.data?.token === false) {
            navigate("/login");
          }
          if (res.data.error) {
          } else {
            setIssueOrders(res.data.issueorders);
            setReturnOrders(res.data.returnorders);
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };

  useEffect(() => {
    getFilterOrders();
  }, []);

  const handleDelete = (id) => {
    setDeleteId(id);
    setDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
    setDeleteId("");
  };
  const handleCloseItemModel = () => {
    setItemModel(false);
    setModelItems(null);
  };

  const deleteProduct = async () => {
    try {
      const token = localStorage.getItem("t5kvs24s46a3n");
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}order/delete/${deleteId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.data?.token === false) {
            navigate("/login");
          }
          if (res.data.error) {
            console.log("error : ", res.data.error);
          } else {
            setDeleteId("");
            setDeleteModal(false);
            getFilterOrders();
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };

  const changeStatus = (id, status) => {
    try {
      const token = localStorage.getItem("t5kvs24s46a3n");
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}order/changestatus`,
          { id, status },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          if (res.data?.token === false) {
            navigate("/login");
          }
          if (res.data.error) {
            console.log("error : ", res.data.error);
            toastError(res.data.error, 2000);
          } else {
            toastSuccess(res.data.message, 2000);
            getFilterOrders();
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };

  useEffect(() => {
    if (!date) {
      getFilterOrders();
    }
  }, [date]);

  useEffect(() => {
    getFilterOrders();
  }, [search]);

  return (
    <div>
      {/* Main Wrapper */}
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Filter Order</h4>
              <br />
              <div className="d-md-flex">
                <div className="search-set">
                  {/* <div className="search-path">
                      <a className="btn btn-filter" id="filter_search">
                        <img src="assets/img/icons/filter.svg" alt="img" />
                        <span>
                          <img src="assets/img/icons/closes.svg" alt="img" />
                        </span>
                      </a>
                    </div> */}
                  <div className="search-input">
                    <button
                      className="btn btn-searchset"
                      onClick={getFilterOrders}
                    >
                      <FiSearch size={20} color="#ff9f43" />
                    </button>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search..."
                          aria-controls="DataTables_Table_0"
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <input
                  type="date"
                  className="form-control mx-md-2 my-md-0 my-1"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
                <button
                  type="button"
                  className="btn btn-primary btn-sm my-md-0 my-1"
                  onClick={() => {
                    getFilterOrders();
                  }}
                >
                  Filter
                </button>
                <button
                  type="button"
                  className="btn btn-secondary btn-sm mx-2 my-md-0 my-1"
                  onClick={() => {
                    setDate("");
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
            <div className="page-btn">
              <Link to="/create-order" className="btn btn-added">
                Create New Order
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <div className="page-header">
                <div className="page-title">
                  <h4>Issue List</h4>
                </div>
              </div>
              <div className="table-responsive">
                <div
                  id="DataTables_Table_0_wrapper"
                  className="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                  <table
                    className="table datanew dataTable no-footer"
                    id="DataTables_Table_0"
                    role="grid"
                    aria-describedby="DataTables_Table_0_info"
                  >
                    <thead>
                      <tr role="row">
                        <th>Sr. No</th>
                        <th>Order Id</th>
                        <th>Customer Name</th>
                        <th>Mobile No. </th>
                        <th>Items</th>
                        <th>Issue Datetime</th>
                        <th>Return Datetime</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {issueOrders && issueOrders.length ? (
                        issueOrders.map((item, index) => {
                          return (
                            <tr className="odd" key={index}>
                              <td>{index + 1}</td>
                              <td>{item.orderid ? item.orderid : "-"}</td>
                              <td>
                                {item.customername ? item.customername : "-"}
                              </td>
                              <td>
                                {item.mobileno ? item.mobileno : "-"} 
                                <Link to={`/send-whatsapp/${item.orderid}`}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-whatsapp"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                                  </svg>
                                </Link>
                              </td>
                              <td>
                                {item.items[0].pname}
                                <button
                                  className="btn btn-secondary btn-sm mx-2"
                                  onClick={() => {
                                    setModelItems([...item.items]);
                                    setItemModel(true);
                                  }}
                                >
                                  Show
                                </button>
                              </td>
                              <td>{formatDate(item.issuedatetime)}</td>
                              <td>{formatDate(item.returndatetime)}</td>
                              <td>
                                {item.description ? item.description : "-"}
                              </td>
                              <td>
                                <select
                                  style={{ width: "100px" }}
                                  className="form-control"
                                  id="pcategory"
                                  value={item.status}
                                  onChange={(e) =>
                                    changeStatus(item._id, e.target.value)
                                  }
                                >
                                  <option value={0}>Created</option>
                                  <option value={1}>Issue</option>
                                  <option value={2}>Returned</option>
                                  <option value={3}>Complete</option>
                                </select>
                              </td>
                              <td>
                                {/* <button
                                    className="me-2"
                                    style={{
                                      background: "none",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      navigate(`/edit-order/${item._id}`);
                                    }}
                                  >
                                    <svg
                                      width="16"
                                      height="20"
                                      viewBox="0 0 16 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M15.045 5.401C15.423 5.023 15.631 4.521 15.631 3.987C15.631 3.453 15.423 2.951 15.045 2.573L13.459 0.987001C13.081 0.609001 12.579 0.401001 12.045 0.401001C11.511 0.401001 11.009 0.609001 10.632 0.986001L0 11.585V16H4.413L15.045 5.401ZM12.045 2.401L13.632 3.986L12.042 5.57L10.456 3.985L12.045 2.401ZM2 14V12.415L9.04 5.397L10.626 6.983L3.587 14H2ZM0 18H16V20H0V18Z"
                                        fill="#637381"
                                      />
                                    </svg>
                                  </button> */}
                                <button
                                  className="confirm-text"
                                  style={{
                                    background: "none",
                                    border: "none",
                                  }}
                                  onClick={() => handleDelete(item._id)}
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5 20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V8H21V6H17V4C17 3.46957 16.7893 2.96086 16.4142 2.58579C16.0391 2.21071 15.5304 2 15 2H9C8.46957 2 7.96086 2.21071 7.58579 2.58579C7.21071 2.96086 7 3.46957 7 4V6H3V8H5V20ZM9 4H15V6H9V4ZM8 8H17V20H7V8H8Z"
                                      fill="#EA5455"
                                    />
                                    <path
                                      d="M9 10H11V18H9V10ZM13 10H15V18H13V10Z"
                                      fill="#EA5455"
                                    />
                                  </svg>
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            colSpan={10}
                            className="sorting_1"
                            style={{ textAlign: "center" }}
                          >
                            No data found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="page-header">
                <div className="page-title">
                  <h4>Return List</h4>
                </div>
              </div>
              <div className="table-responsive">
                <div
                  id="DataTables_Table_0_wrapper"
                  className="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                  <table
                    className="table datanew dataTable no-footer"
                    id="DataTables_Table_0"
                    role="grid"
                    aria-describedby="DataTables_Table_0_info"
                  >
                    <thead>
                      <tr role="row">
                        <th>Sr. No</th>
                        <th>Order Id</th>
                        <th>Customer Name</th>
                        <th>Mobile No. </th>
                        <th>Items</th>
                        <th>Issue Datetime</th>
                        <th>Return Datetime</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {returnOrders && returnOrders.length ? (
                        returnOrders.map((item, index) => {
                          return (
                            <tr className="odd" key={index}>
                              <td>{index + 1}</td>
                              <td>{item.orderid ? item.orderid : "-"}</td>
                              <td>
                                {item.customername ? item.customername : "-"}
                              </td>
                              <td>{item.mobileno ? item.mobileno : "-"} 
                              <Link to={`/send-whatsapp/${item.orderid}`}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-whatsapp"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                                  </svg>
                                </Link>
                              </td>
                              <td>
                                {item.items[0].pname}
                                <button
                                  className="btn btn-secondary btn-sm mx-2"
                                  onClick={() => {
                                    setModelItems([...item.items]);
                                    setItemModel(true);
                                  }}
                                >
                                  Show
                                </button>
                              </td>
                              <td>{formatDate(item.issuedatetime)}</td>
                              <td>{formatDate(item.returndatetime)}</td>
                              <td>
                                {item.description ? item.description : "-"}
                              </td>
                              <td>
                                <select
                                  style={{ width: "100px" }}
                                  className="form-control"
                                  id="pcategory"
                                  value={item.status}
                                  onChange={(e) =>
                                    changeStatus(item._id, e.target.value)
                                  }
                                >
                                  <option value={0}>Created</option>
                                  <option value={1}>Issue</option>
                                  <option value={2}>Returned</option>
                                  <option value={3}>Complete</option>
                                </select>
                              </td>
                              <td>
                                {/* <button
                                    className="me-2"
                                    style={{
                                      background: "none",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      navigate(`/edit-order/${item._id}`);
                                    }}
                                  >
                                    <svg
                                      width="16"
                                      height="20"
                                      viewBox="0 0 16 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M15.045 5.401C15.423 5.023 15.631 4.521 15.631 3.987C15.631 3.453 15.423 2.951 15.045 2.573L13.459 0.987001C13.081 0.609001 12.579 0.401001 12.045 0.401001C11.511 0.401001 11.009 0.609001 10.632 0.986001L0 11.585V16H4.413L15.045 5.401ZM12.045 2.401L13.632 3.986L12.042 5.57L10.456 3.985L12.045 2.401ZM2 14V12.415L9.04 5.397L10.626 6.983L3.587 14H2ZM0 18H16V20H0V18Z"
                                        fill="#637381"
                                      />
                                    </svg>
                                  </button> */}
                                <button
                                  className="confirm-text"
                                  style={{
                                    background: "none",
                                    border: "none",
                                  }}
                                  onClick={() => handleDelete(item._id)}
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5 20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V8H21V6H17V4C17 3.46957 16.7893 2.96086 16.4142 2.58579C16.0391 2.21071 15.5304 2 15 2H9C8.46957 2 7.96086 2.21071 7.58579 2.58579C7.21071 2.96086 7 3.46957 7 4V6H3V8H5V20ZM9 4H15V6H9V4ZM8 8H17V20H7V8H8Z"
                                      fill="#EA5455"
                                    />
                                    <path
                                      d="M9 10H11V18H9V10ZM13 10H15V18H13V10Z"
                                      fill="#EA5455"
                                    />
                                  </svg>
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            colSpan={10}
                            className="sorting_1"
                            style={{ textAlign: "center" }}
                          >
                            No data found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <Modal
        show={deleteModal}
        onHide={handleCloseDeleteModal}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you really want to delete?</Modal.Body>
        <Modal.Footer>
          <Button
            className="p-2"
            variant="outline-secondary"
            size="sm"
            onClick={handleCloseDeleteModal}
          >
            Close
          </Button>
          <Button
            className="p-2"
            variant="outline-danger"
            size="sm"
            onClick={deleteProduct}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={itemModel} onHide={handleCloseItemModel} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>No.</th>
                <th style={{ textAlign: "center" }}>P. Id</th>
                <th style={{ textAlign: "center" }}>P. Name</th>
                <th style={{ textAlign: "center" }}>P. Cat</th>
              </tr>
            </thead>
            <tbody>
              {modelItems && modelItems.length
                ? modelItems?.map((item1, ind) => (
                    <tr key={item1.pid}>
                      <td style={{ textAlign: "center" }}>{ind + 1}</td>
                      <td style={{ textAlign: "center" }}>{item1.pid}</td>
                      <td style={{ textAlign: "center" }}>{item1.pname}</td>
                      <td style={{ textAlign: "center" }}>{item1.pcategory}</td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="p-2"
            variant="outline-secondary"
            size="sm"
            onClick={handleCloseItemModel}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FilterOrder;
