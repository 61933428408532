import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../components/Pagination";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FiChevronsRight } from "react-icons/fi";
import { FiSearch } from "react-icons/fi";

const WhatsappList = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [category, setCategory] = useState(null);
  const [search, setSearch] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const getCategory = () => {
    try {
      const token = localStorage.getItem("t5kvs24s46a3n");
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}w_list/get`,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          if (res.data?.token === false) {
            navigate("/login");
          }
          if (res.data.error) {
            console.log("error : ", res.data.error);
          } else {
            setCategory(res.data.items);
            setPages(res.data.pages);
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };
  useEffect(() => {
    getCategory();
  }, [pageSize, currentPage]);

  const handleDelete = (id) => {
    setDeleteId(id);
    setDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
    setDeleteId("");
  };

  const deleteCategory = async () => {
    try {
      const token = localStorage.getItem("t5kvs24s46a3n");
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}category/delete/${deleteId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.data?.token === false) {
            navigate("/login");
          }
          if (res.data.error) {
            console.log("error : ", res.data.error);
          } else {
            setDeleteId("");
            setDeleteModal(false);
            getCategory();
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };

  useEffect(() => {
    getCategory();
  }, [search]);

  return (
    <div>
      {/* Main Wrapper */}

      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>WhatsApp List</h4>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <button className="btn btn-searchset" onClick={getCategory}>
                      <FiSearch size={20} color="#ff9f43" />
                    </button>
                    <div id="DataTables_Table_0_filter" className="dataTables_filter">
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search..."
                          aria-controls="DataTables_Table_0"
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                  <table
                    className="table datanew dataTable no-footer"
                    id="DataTables_Table_0"
                    role="grid"
                    aria-describedby="DataTables_Table_0_info"
                  >
                    <thead>
                      <tr role="row">
                        <th>Sr. No</th>
                        <th>Action</th>
                        <th>Title</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {category && category.length ? (
                        category.map((item, index) => {
                          return (
                            <tr className="odd" key={index + 1}>
                              <td className="sorting_1">{index + 1}</td>
                              <td>
                                <button
                                  className="me-2"
                                  style={{
                                    background: "none",
                                    border: "none",
                                  }}
                                  onClick={() => navigate(`/edit-whatsapp/${item._id}`)}
                                >
                                  <svg
                                    width="16"
                                    height="20"
                                    viewBox="0 0 16 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M15.045 5.401C15.423 5.023 15.631 4.521 15.631 3.987C15.631 3.453 15.423 2.951 15.045 2.573L13.459 0.987001C13.081 0.609001 12.579 0.401001 12.045 0.401001C11.511 0.401001 11.009 0.609001 10.632 0.986001L0 11.585V16H4.413L15.045 5.401ZM12.045 2.401L13.632 3.986L12.042 5.57L10.456 3.985L12.045 2.401ZM2 14V12.415L9.04 5.397L10.626 6.983L3.587 14H2ZM0 18H16V20H0V18Z"
                                      fill="#637381"
                                    />
                                  </svg>
                                </button>
                                
                              </td>
                              <td>{item.title ? item.title : "-"}</td>

                              <td>{item.message ? item.message : 0}</td>

                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={4} className="sorting_1" style={{ textAlign: "center" }}>
                            No data found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pages={pages}
              />
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <Modal show={deleteModal} onHide={handleCloseDeleteModal} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you really want to delete?</Modal.Body>
        <Modal.Footer>
          <Button className="p-2" variant="outline-secondary" size="sm" onClick={handleCloseDeleteModal}>
            Close
          </Button>
          <Button className="p-2" variant="outline-danger" size="sm" onClick={deleteCategory}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default WhatsappList;
