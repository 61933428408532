import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";

const SendWhatsappMsg = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [message, setmessage] = useState("");
  const [onId, setonId] = useState("");
  const [wlinkapi, setwlinkapi] = useState("");
  const [wlink, setwlink] = useState("");
  const [orderdata, setorderdata] = useState(null);
  const [allmsgs, setallmsgs] = useState([]);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const getCategory = () => {
    try {
      const token = localStorage.getItem("t5kvs24s46a3n");
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}w_list/get`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.data?.token === false) {
            navigate("/login");
          }
          if (res.data.error) {
          } else {
            setallmsgs(res.data.items);
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };
  const getOrder = () => {
    try {
      const token = localStorage.getItem("t5kvs24s46a3n");
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}order/getonebyid/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          
          if (res.data?.token === false) {
            navigate("/login");
          }
          if (res.data.error) {
          } else {
            console.log("ressasda", res.data);
            setorderdata(res.data[0]);
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };
const editmsg = (value='')=>{
 var das= value.replace('$name',`*${orderdata ? orderdata.customername:''}*` );
  var number= das.replace('$number', `*${orderdata ? orderdata.mobileno:''}*`);
  var idate= number.replace('$idate', `*${orderdata ? new Date(orderdata.issuedatetime).toLocaleString():''}*`);
  var rdate= idate.replace('$rdate', `*${orderdata ? new Date(orderdata.returndatetime).toLocaleDateString():''}*`);
  var order= rdate.replace('$order', `*#${orderdata ? orderdata.orderid:''}*`);
  const formattedStrings = orderdata.items.map(item => `*${item.pid} - ${item.pname} - ${item.pcategory}*`);
  
  // Join all formatted strings with newline characters
  const resultString = formattedStrings.join('\n');
  var orderString= order.replace('$items', `${resultString}`);

  const formattedMessage = encodeURIComponent(orderString).replace(/%0A/g, '%0A');

  setmessage(orderString)
  setwlink(`https://web.whatsapp.com/send?phone=${orderdata.mobileno}&text=${formattedMessage}`)
  setwlinkapi(`https://api.whatsapp.com/send?phone=${orderdata.mobileno}&text=${formattedMessage}`)
  // setwlink(`https://web.whatsapp.com/send?phone=8154059608&text=${formattedMessage}`)
}
  
  useEffect(() => {
    getCategory();
  }, []);
  useEffect(() => {
    getOrder();
    setonId(id);
  }, [id]);

  return (
    <div>
      {/* Main Wrapper */}
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Send Whatsapp</h4>
            </div>
          </div>
          {/* /add */}
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label htmlFor="pcategory">Order ID#</label>
                    <input
                      type="text"
                      // type="datetime-local"
                      className="form-control"
                      id="cname"
                      placeholder="Order ID"
                      value={onId}
                      onChange={(e) => setonId(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <br />
                    <Link className="btn btn-submit me-2" to={`/send-whatsapp/${onId}`}>Get</Link>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label htmlFor="pcategory">Name</label>
                    <span>{orderdata ? orderdata.customername:''}</span>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="form-group">
                    <label htmlFor="pcategory">Mobile Number</label>
                    <span>{orderdata ? orderdata.mobileno:''}</span>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label htmlFor="pcategory">Description</label>
                    <span>{orderdata ? orderdata.description:''}</span>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label htmlFor="pcategory">Issue Date</label>
                    <span>{orderdata ? new Date(orderdata.issuedatetime).toLocaleString():''}</span>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label htmlFor="pcategory">Return Date</label>
                    <span>{orderdata ? new Date(orderdata.returndatetime).toLocaleString():''}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label htmlFor="pcategory">Title</label>
                    <select
                      className="form-control"
                      id="pcategory"
                      // ref={pcatref}
                      onChange={(e) => editmsg(e.target.value)}
                    >
                      <option value="" selected>
                        -Select-
                      </option>
                      {allmsgs && allmsgs.length
                        ? allmsgs.map((item, index) => {
                            return (
                              <option key={item.wid} value={item.message}>
                                {item.title}
                              </option>
                            );
                          })
                        : ""}
                    </select>
                  </div>

                </div>
                <div className="col-lg-6 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="description">Message</label>
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <textarea
                      rows={90}
                      className="form-control"
                      value={message}
                      style={{ height: "300px" }}
                      placeholder="Write here."
                      onChange={(e) => {setmessage(e.target.value);editmsg(e.target.value)}}
                    ></textarea>
                  </div>
                </div>

                {error ? (
                  <div className="alert alert-danger p-1 mx-3" role="alert">
                    {error}
                  </div>
                ) : (
                  ""
                )}
                {success ? (
                  <div className="alert alert-success p-1 mx-3" role="alert">
                    {success}
                  </div>
                ) : (
                  ""
                )}
                <div className="col-lg-12">
                  <a href={wlink} target="_blank"  className="btn btn-submit me-2">Send Web</a>
                  <a href={wlinkapi} target="_blank"  className="btn btn-submit me-2">Send Mobile</a>
                </div>
              </div>
            </div>
          </div>
          {/* /add */}
        </div>
      </div>
    </div>
  );
};

export default SendWhatsappMsg;
