import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AddWhatsappNote = () => {
  const navigate = useNavigate();
  const [pid, setPid] = useState(new Date().getTime().toString());
  const [pname, setPname] = useState("");
  const [title, settitle] = useState("");
  const [message, setmessage] = useState("");
  const [pnameErr, setPnameErr] = useState("");
  const [categories, setCategories] = useState([]);
  const pimageref = useRef(null);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const reset = () => {
    setPid(new Date().getTime().toString());
    setmessage("");
    settitle("");
    setError("");
  };

  const addProduct = async () => {
    setError("");
    setSuccess("");
    let temp = true;
    if (!title) {
      setPnameErr("Title is require.");
      temp = false;
    } else {
      setPnameErr("");
    }

    if (temp) {
      const token = localStorage.getItem("t5kvs24s46a3n");
      let formData = new FormData();
      formData.append("wid", `W${pid}`);
      formData.append("message", message);
      formData.append("title", title);
      try {
        axios
          .post(`${process.env.REACT_APP_BACKEND_URL}w_list/insert`, formData, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.data?.token === false) {
              navigate("/login");
            }
            if (res.data.error) {
              setSuccess("");
              setError(res.data.error);
            } else {
              setError("");
              setSuccess(res.data.message);
              reset();
            }
          })
          .catch((err) => {
            console.log("error : ", err);
            setError(err.message);
          });
      } catch (err) {
        console.log("error : ", err);
        setError(err.message);
      }
    }
  };

  const getCategory = () => {
    try {
      const token = localStorage.getItem("t5kvs24s46a3n");
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}w_list/get`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.data?.token === false) {
            navigate("/login");
          }
          if (res.data.error) {
          } else {
            setCategories(res.data);
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <div>
      {/* Main Wrapper */}
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Add Whatsapp Message</h4>
              {/* <h6>Create new product</h6> */}
            </div>
          </div>
          {/* /add */}
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="pname">Title</label>
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <input
                      type="text"
                      // type="datetime-local"
                      className="form-control"
                      id="pname"
                      placeholder="Title"
                      value={title}
                      onChange={(e) => settitle(e.target.value)}
                    />
                    {pnameErr ? (
                      <small
                        id="emailHelp"
                        style={{ color: "#FF0000", fontSize: "0.8rem" }}
                      >
                        {pnameErr}
                      </small>
                    ) : (
                      ""
                    )}
                    {/* <small id="emailHelp" className="form-text text-muted">
                        We'll never share your email with anyone else.
                      </small> */}
                  </div>
                  <div className="form-group">
                    <p>$order = Order Number</p>
                    <p>$rdate = Return Date</p>
                    <p>$idate = Issue Date</p>
                    <p>$number = Mobile Number</p>
                    <p>$name = Customer Name</p>
                    <p>$items = Product list </p>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="description">Message</label>
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <textarea
                      rows={90}
                      className="form-control"
                      value={message}
                      placeholder="Write here."
                      style={{height:"300px"}}
                      onChange={(e) => setmessage(e.target.value)}
                    ></textarea>
                  </div>
                </div>

                {error ? (
                  <div className="alert alert-danger p-1 mx-3" role="alert">
                    {error}
                  </div>
                ) : (
                  ""
                )}
                {success ? (
                  <div className="alert alert-success p-1 mx-3" role="alert">
                    {success}
                  </div>
                ) : (
                  ""
                )}
                <div className="col-lg-12">
                  <button className="btn btn-submit me-2" onClick={addProduct}>
                    Submit
                  </button>
                  <button className="btn btn-cancel" onClick={reset}>
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* /add */}
        </div>
      </div>
    </div>
  );
};

export default AddWhatsappNote;
